import { decodeBase64 } from "https://deno.land/std@0.206.0/encoding/base64.ts";

export const IMAGE_DIR = "images";
const BASE_API_URL = 'https://cdn.apotekshuset.net'
export const FILE_API = `${BASE_API_URL}`;

export function showFile(image: string) {
  return `${FILE_API}/?file=${image}`;
}

export async function fileUpload(data: string, fileName: string) {
  const isExist = await exists(IMAGE_DIR);
  if (!isExist) {
    await Deno.mkdir(IMAGE_DIR);
  }

  try {
    const decodedImage = decodeBase64(data);
    await Deno.writeFile(`${IMAGE_DIR}/${fileName}`, decodedImage, {
      create: true,
    });
    return fileName;
  } catch (error) {
    console.error("Error saving file:", error);
    throw error;
  }
}

export async function deleteFile(file: string) {
  try {
    const isExist = await exists(`${IMAGE_DIR}/${file}`);
    if (isExist) {
      Deno.remove(`${IMAGE_DIR}/${file}`);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function exists(path: string) {
  try {
    await Deno.stat(path);
    return true;
  } catch (_error) {
    return false;
  }
}
